// src/components/WhatsAppButton.js
import React from "react";
import styled from "styled-components";
import { FaWhatsapp } from "react-icons/fa";

const FloatingButton = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  color: #fff;
  padding: 0.8rem;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #20b358;
  }
`;

function WhatsAppButton() {
  return (
    <FloatingButton
      href="https://wa.me/5511980660741?text=Olá,%20gostaria%20de%20saber%20de%20um%20seus%20serviços." // Substitua pelo seu número de WhatsApp
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Contact us on WhatsApp"
    >
      <FaWhatsapp size="1.5rem" />
    </FloatingButton>
  );
}

export default WhatsAppButton;
