import React from 'react';
import styled from 'styled-components';

const AboutSection = styled.section`
  background-image: url('/img/background_image.jpeg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
  padding: 100px 30px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza horizontalmente */
  justify-content: center; /* Centraliza verticalmente */
  height: 60vh; /* Ocupa a altura completa da tela */
  text-align: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  div {
    position: relative;
    z-index: 2;
  }
`;

const AboutHeading = styled.h2`
  font-size: 3rem;
  margin-bottom: 20px;
`;

const AboutDescription = styled.p`
  font-size: 1.5rem;
  max-width: 800px;
  margin: 0 auto 20px; /* Espaço abaixo do texto */
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 200px;
  background-color: yellow;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); /* Losango */
  margin: 20px auto 0; /* Centraliza horizontalmente e adiciona margem superior */
`;

const LogoImage = styled.img`
  width: 60%;
  height: 60%;
  border-radius: 50%; /* Círculo */
`;

const About = () => {
  return (
    <AboutSection id="about">
      <div>
        <AboutHeading>Quem Somos</AboutHeading>
        <AboutDescription>
          Somos a CompactCode Brasil, uma empresa dedicada ao desenvolvimento de soluções web de alta qualidade, focadas nas necessidades dos nossos clientes e na inovação tecnológica.
        </AboutDescription>
        <LogoContainer>
          <LogoImage src="/img/logo3d.png" alt="Logo" />
        </LogoContainer>
      </div>
    </AboutSection>
  );
};

export default About;
