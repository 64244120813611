import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import WhatsAppButton from "./components/WhatsAppButton";
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>CompactCode Brasil</title>
        <meta name="description" content="Desenvolvemos projetos web personalizados para sua empresa. Confira nossos serviços e projetos realizados." />
        <meta name="keywords" content="desenvolvimento web, projetos web, serviços de TI, landing page" />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Nome da Empresa" />
        <meta property="og:title" content="Landing Page - Desenvolvimento Web" />
        <meta property="og:description" content="Veja nossos projetos web e serviços de desenvolvimento." />
        <meta property="og:url" content="https://www.seusite.com" />
        <meta property="og:type" content="website" />
      </Helmet>

      <Header />
      <About />
      <Projects />
      <Services />
      <Testimonials />
      <Contact />
      <Footer />
      <WhatsAppButton />
    </div>
  );
}

export default App;
