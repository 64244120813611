import React from 'react';
import styled from 'styled-components';
import { useForm, ValidationError } from '@formspree/react';

const ContactSection = styled.section`
  padding: 60px 30px;
  background-color: #d3f1f3;
  text-align: center;
`;

const ContactHeading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #070707;
`;

const ContactForm = styled.form`
  max-width: 600px;
  margin: 0 auto;
  text-align: left;

  label {
    font-size: 1rem;
    margin-bottom: 5px;
    display: block;
  }

  input, textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

const ContactInfo = styled.div`
  margin-top: 30px;
  font-size: 1rem;
  color: #0a0a0a;
`;

const Contact = () => {
  const [state, handleSubmit] = useForm("movqvqkp");

  if (state.succeeded) {
    return <p>Obrigado por entrar em contato!</p>;
  }

  return (
    <ContactSection id="contact">
      <ContactHeading>Contato</ContactHeading>

      {/* Formspree form submission logic */}
      <ContactForm onSubmit={handleSubmit}>
        <label htmlFor="name">Nome</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" required />
        <ValidationError 
          prefix="Email" 
          field="email"
          errors={state.errors}
        />

        <label htmlFor="message">Mensagem</label>
        <textarea id="message" name="message" rows="5" required></textarea>
        <ValidationError 
          prefix="Message" 
          field="message"
          errors={state.errors}
        />

        <button type="submit" disabled={state.submitting}>Enviar</button>
      </ContactForm>

      <ContactInfo>
        Email: contato@wiltongoncalves.com.br<br />
        Telefone: (11) 98066-0741
        
      </ContactInfo>
    </ContactSection>
  );
};

export default Contact;
