import React from 'react';
import styled from 'styled-components';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Importando o Font Awesome

const FooterWrapper = styled.footer`
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
`;

const SocialIcons = styled.div`
  margin: 20px 0;

  a {
    margin: 0 10px;
    color: white;
    font-size: 1.2rem;
    transition: color 0.3s ease;

    &:hover {
      color: #ffc107;
    }
  }
`;

const Copyright = styled.p`
  margin-top: 15px;
  font-size: 0.9rem;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <SocialIcons>
        <a href="https://www.facebook.com/profile.php?id=100091244591986" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="https://github.com/Wilton-compactcode" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-github"></i>
        </a>
        <a href="https://www.instagram.com/wiltongoncalves.dev/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://www.linkedin.com/in/wilton-goncalves-santana-dev-web/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin"></i>
        </a>
      </SocialIcons>
      <Copyright>
        &copy; {new Date().getFullYear()} CompactCode Brasil. Todos os direitos reservados.
      </Copyright>
    </FooterWrapper>
  );
};

export default Footer;