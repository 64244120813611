import React from 'react';
import styled from 'styled-components';
import { FaCode, FaMobileAlt, FaRocket, FaChartLine } from 'react-icons/fa';

const ServicesSection = styled.section`
  padding: 80px 20px;
  background-color: #71b2f0;
  text-align: center;
`;

const ServicesHeading = styled.h2`
  font-size: 2.8rem;
  color: #0c0c0c;
  margin-bottom: 40px;
`;

const ServiceCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
`;

const ServiceCard = styled.div`
  background-color: #cdd3ec;
  border-radius: 10px;
  padding: 40px 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  svg {
    font-size: 3rem;
    color: #007bff;
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.8rem;
  color: #333;
  margin-top: 20px;
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
`;

const Services = () => {
  const services = [
    {
      icon: <FaCode />,
      title: 'Desenvolvimento Personalizado',
      description: 'Soluções sob medida para sua empresa, transformando ideias em realidade com tecnologias de ponta.',
    },
    {
      icon: <FaMobileAlt />,
      title: 'Aplicativos Mobile',
      description: 'Criação de aplicativos móveis modernos e intuitivos para Android e iOS, com foco na experiência do usuário.',
    },
    {
      icon: <FaRocket />,
      title: 'Otimização de Performance',
      description: 'Aceleramos a velocidade e a eficiência dos seus sistemas, garantindo uma experiência de usuário impecável.',
    },
    {
      icon: <FaChartLine />,
      title: 'Análise de Dados e Integração',
      description: 'Integramos soluções inteligentes de análise de dados para impulsionar suas decisões estratégicas e aumentar o ROI.',
    },
  ];

  return (
    <ServicesSection id="services">
      <ServicesHeading>Nossos Serviços</ServicesHeading>
      <ServiceCards>
        {services.map((service, index) => (
          <ServiceCard key={index}>
            {service.icon}
            <ServiceTitle>{service.title}</ServiceTitle>
            <ServiceDescription>{service.description}</ServiceDescription>
          </ServiceCard>
        ))}
      </ServiceCards>
    </ServicesSection>
  );
};

export default Services;
