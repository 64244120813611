import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 3rem 1rem;
  background-color: #ade4ee;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
`;

const TestimonialList = styled.div`
  display: flex;
  flex-direction: row; /* Alinha os cards na horizontal */
  justify-content: center; /* Centraliza os cards na seção */
  flex-wrap: wrap; /* Permite quebra de linha em telas menores */
  gap: 1.5rem; /* Espaçamento entre os cards */
`;

const Testimonial = styled.div`
  background-color: #84baec;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const TestimonialImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 1rem;
`;

const Testimonials = () => {
  const testimonials = [
    { name: "Marcos Santana", feedback: "Serviço excelente, muito profissional!", image: "/img/chaveiro.png" },
    { name: "Edsons", feedback: "Atendimento excepcional e resultados incríveis.", image: "/img/aco_iguatemi.png" },
    { name: "J.L Terapia Alternativa", feedback: "Recomendo para todos que buscam qualidade e atenção!", image: "/img/jlterapia-alternativa.jpg" }
  ];

  return (
    <Section id="testimonials">
      <Title>Depoimentos</Title>
      <TestimonialList>
        {testimonials.map((testimonial, index) => (
          <Testimonial key={index}>
            <TestimonialImage src={testimonial.image} alt={testimonial.name} />
            <div>
              <p>{testimonial.feedback}</p>
              <small>➣ {testimonial.name}</small>
            </div>
          </Testimonial>
        ))}
      </TestimonialList>
    </Section>
  );
};

export default Testimonials;
