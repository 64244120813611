import React from 'react';
import styled from 'styled-components';

const ProjectsSection = styled.section`
  padding: 60px 30px;
  background-color: #98c4ee;
`;

const ProjectsHeading = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #0c0c0c;
`;

const ProjectGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
`;

const ProjectItem = styled.div`
  background-color: #f4f4f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
`;

const ProjectTitle = styled.h3`
  margin-top: 15px;
  font-size: 1.5rem;
`;

const ProjectDescription = styled.p`
  margin-top: 10px;
  font-size: 1rem;
`;

const Projects = () => {
  const projectList = [
    {
      title: 'Projeto I',
      description: 'Desenvolvimento de e-commerce',
      imageUrl: '/img/e-comerce.jpeg'
    },
    {
      title: 'Projeto II',
      description: 'Website institucional',
      imageUrl: '/img/institucional.jpeg'
    },
    {
      title: 'Projeto III',
      description: 'Aplicativo web customizado',
      imageUrl: '/img/costumizado.jpg'
    },
  ];

  return (
    <ProjectsSection id="projects">
      <ProjectsHeading>Nossos Projetos</ProjectsHeading>
      <ProjectGallery>
        {projectList.map((project, index) => (
          <ProjectItem key={index}>
            <img src={project.imageUrl} alt={project.title} />
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectDescription>{project.description}</ProjectDescription>
          </ProjectItem>
        ))}
      </ProjectGallery>
    </ProjectsSection>
  );
};

export default Projects;
